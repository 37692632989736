import { RouteRecordRaw } from 'vue-router';
import router from '.';
import store from '@/store';

// 根据菜单数据和权限信息动态生成路由
const getMenuAndPermissions = (routerList) => {
    //进行递归解析
    //routerList 后端获取的路由
    let res = [] as any
    // 添加逻辑根据权限过滤路由等
    routerList.forEach(item => {
        let e_new = {
            path: item.route,
            name: item.name,
            meta: {
                title: item.name,
                icon: item.icon
            },
            component: item.superior != 0 ? () => import('@/views' + item.route + '.vue') : null,
            children: []
        }

        if (item.sonSystemPermission.length > 0) {
            const children = getMenuAndPermissions(item.sonSystemPermission)
            // 保存权限
            e_new.children = children
        }
        res.push(e_new)
    })
    return res
};

/** 加载动态菜单 */
export const loadDynamicRoutes = () => {
    const routes = localStorage.getItem('routes') ? JSON.parse(localStorage.getItem('routes') || '') : []
    const menus = getMenuAndPermissions(routes)
    // 将动态路由添加到路由器
    addDynamicRoutes(menus);
    store.commit('setMenuList', menus)
};

// 动态添加路由的函数
function addDynamicRoutes(newRoutes: RouteRecordRaw[]) {
    newRoutes.forEach((route) => {
        router.addRoute('Index', route);
    });
}

