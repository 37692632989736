import { createStore } from 'vuex'

const state = {
    asyncRoutesMark: false,
    menu: [],
    routers: [],
}

const mutations = {
    setAsyncRoutesMark(state: { asyncRoutesMark: any }, data: any) {
        state.asyncRoutesMark = data
    },

    setMenuList(state: { menu: any }, data: any) {
        state.menu = data
    },

    setRouters(state: { routers: any }, data: any) {
        state.routers = data
    },
}


const options = {
    state,
    mutations,
}

const store = createStore(options)
export default store
