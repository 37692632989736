import "core-js/modules/es.array.push.js";
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import store from '../store/index';
import { ArrowRight } from '@element-plus/icons-vue';
export default defineComponent({
  name: 'IndexView',
  setup() {
    const loginOut = () => {
      localStorage.removeItem('token');
      localStorage.removeItem("user");
      localStorage.removeItem("routes");
      router.push('/login');
    };
    const data = reactive({
      breadList: [],
      menus: [],
      userInfo: JSON.parse(localStorage.getItem('user') || "{}")
    });
    let router = useRouter();
    let route = useRoute();
    let getMatched = () => {
      data.breadList = route.matched.filter(item => item.meta && item.meta.title);
    };
    let getMenus = () => {
      data.menus = store.state.menu;
    };
    onMounted(() => {
      getMatched();
      getMenus();
    });
    const activeMenu = ref('/');
    watch(route, newRoute => {
      activeMenu.value = newRoute.path;
    }, {
      immediate: true // 初始时执行一次
    });
    watch(() => route.path, (newValue, oldValue) => {
      data.breadList = route.matched.filter(item => item.meta && item.meta.title);
    });
    return {
      ...toRefs(data),
      loginOut,
      ArrowRight,
      activeMenu
    };
  }
});