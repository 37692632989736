import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import IndexView from '../views/index.vue'
import store from '../store/index'
import { loadDynamicRoutes } from './dynamicRoutes'

//constantRoutes 静态路由 登陆，首页等。。。
export const constantRoutes = [
  {
    path: "/",
    name: 'Index',
    redirect: '/statistics/index',
    meta: {
      title: '首页',
      icon: "House",
    },
    component: IndexView,
    children: [{
      path: "/statistics/index",
      name: "statistics",
      meta: {
        title: '统计'
      },
      component: () => import('@/views/statistics/index.vue'),
      hidden: true
    }]
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login.vue'),
    hidden: true
  },
  {
    path: '/:pathMatch(.*)*',// 此写法解决动态路由页面刷新的 warning 警告
    component: () => import('@/views/sys/404.vue'),
    hidden: true
  },
]

/** 初始化路由 */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes
})

/** 路由守卫 */
router.beforeEach((to, from, next) => {
  const isLoadRouters = store.state.asyncRoutesMark
  const token = localStorage.getItem('token')

  //有token routes
  if (to.path == '/login') {
    next()
  } else {
    //用户已登录
    if (token) {
      if (isLoadRouters) {
        //console.log('路由已添加，直接跳转到目标页面')
        next()
      } else {
        //解决刷新页面空白
        store.commit('setAsyncRoutesMark', true)

        // 调用加载动态路由的函数
        loadDynamicRoutes();

        next({
          path: `${to.path}`
        })
      }
    } else {
      // console.log('无登录信息，跳转到登录页');
      store.commit('setMenuList', [])
      store.commit('setAsyncRoutesMark', false)
      next(`/login`)
    }
  }
})

// router.beforeEach((to, from, next) => {
//   const token: string | null = localStorage.getItem('token')
//   if (!token && to.path !== '/login') {
//     next('/login')
//   } else {
//     next()
//   }
// })

export default router
